import PrintReceiptButton from "../Components/Shared/UI/PrintReceiptButton/PrintReceiptButton";
import { convertDate, formatNumber } from "../Components/Utils/HelperFunctions";

// ----------------Constant Data---------------
export const FRACTIONAL_DESKTOP_IMAGE_URL = 'https://ddk0b51k9qpea.cloudfront.net/public-resources/images/FractionalOwnershipDesktop.webp';
export const FRACTIONAL_MOBILE_IMAGE_URL = 'https://ddk0b51k9qpea.cloudfront.net/public-resources/images/fractional-compressed-mobile.webp';

export const DOWNLOAD_PDF_HEADER =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/downloadPdfHeader.jpg";
export const ICON_BASE_URL =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/";
export const SCHEDULE_CALL =
  "https://marketing.aurumwisex.com/meetings/booking-calendar/30mins";
export const HERO_HEADING_1 = "Democratising";
export const HERO_HEADING_2 = "Real Estate Investments";
export const AUTH_LAYOUT_PARA =
  "Access High-Yield Institutional Grade Investment Opportunities in the Real Estate Sector. Start Investing with 15 Lakhs.";
export const HERO_SUB_HEADING =
  "Access High-Yield Institutional Grade Investment Opportunities in the Real Estate Sector";
export const HERO_SUB_HEADING2 = "Start Investing with ₹15 Lacs";
export const GET_STARTED_BTN = "Get Started";
export const ABOUT_WISEX_BTN = "About WiseX";
export const LISTING_BOTTOM_HEADING =
  "is India’s first Tech Powered Neo-Realty Investment Platform";
export const WISEX_TEXT = "WiseX";
export const LISTING_BOTTOM_TEXT =
  "which enables retail investors to participate in institutional grade investment opportunities.";
export const WISEX_FACTOR_HEADING =
  "We are making lucrative realty investments accessible to all.";
export const HERO_BG_MOBILE_IMAGE_URL = 'https://ddk0b51k9qpea.cloudfront.net/public-resources/images/HeroBgcropperd.webp';
export const HERO_BG_DESKTOP_IMAGE_URL = 'https://ddk0b51k9qpea.cloudfront.net/public-resources/images/HeroBg.webp';
export const HOW_TO_INVEST = "How to invest.";
export const INVESTMENT_HEADING = "Invest Smartly, Invest Simply with";
export const WE_WORK_WITH1 = "We Work with the ";
export const WE_WORK_WITH2 = "Best";
export const MEAIDA_HEADING = "in the news";
export const BLOG_HEADING = "Featured ";
export const START_WITH_US_BTN = "Get Started";
export const START_WITH_US_BTN_ABOUT_US = "Join our Team";
export const NEWS_LETTER = "Newsletter";
export const CUSTOMER_TESTIMONIALS_SUB = "Why Investors love ";
export const NEWS_LETTER_INPUT_PLACEHOLDER = "Enter your email here";
export const FAQ_HEADING1 = "Looking for help?";
export const FAQ_HEADING2 = "Here are our most frequently asked questions.";
export const FAQ_HEADING_SUB =
  "Everything you need to know about WiseX. We’ve compiled a list of commonly asked questions to provide you with quick and informative answers.";

export const AUTH_LAYOUT_HEADING1 = "Sign Up to Access";

export const AUTH_LAYOUT_LOGIN_HEADING1 = "Sign In to Access";
export const AUTH_LAYOUT_HEADING2 = "Exclusive Investment Opportunities";

export const AUTH_LAYOUT_LOGIN_HEADING2 =
  "and Participate in Smart Real Estate Investments";
export const CREATE_ACC = "Create an account";

export const LOGIN = "Login";
export const ENTER_OTP = "Enter OTP";
export const VERIFY_OTP_TEXT = "Verify the OTP sent on";
export const LOGIN_TEXT =
  "Diversify through non-market linked investment Opportunities offering attractive risk-adjusted returns";
export const REGISTER_TEXT = "Complete your Registration";
export const TERMS_TEXT1 = "By registering, you agree to our ";
export const TERMS_TEXT2 = "Terms of Use & Privacy Policy";
export const CREAM_HERO_TEXT1 = "CREAM PMS - Composite Real Estate";
export const CREAM_HERO_TEXT2 = "Enhanced Active Multi-Asset";
export const CREAM_HERO_SUB_TEXT =
  "India's 1st Sector Specific Real Estate PMS";
export const SDI_HEADING1 = "How to Invest with";
export const SDI_HEADING_SPAN = "with WiseX";
export const CREAM_HERO_KEY_HIGHLIGHTS = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/security-user.svg",
    text: "SEBI Regulated",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/rupess.svg",
    text: "Listed Instrument",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/rupees-arrrow.png",
    text: "Instant Liquidity",
  },
];
export const SDI_HERO_KEY_HIGHLIGHTS = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/security-user.svg",
    text: "SEBI Regulated",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/rupess.svg",
    text: "Listed and Rated Instrument",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/rupees-arrrow.png",
    text: "Fixed Income",
  },
];
export const CORPORATE_BONDS_KEY_HIGHLIGHTS = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/rupess.svg",
    text: "Up to 15% IRR",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/clipboard1.svg",
    text: "Secured Returns",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/rupees-arrrow.png",
    text: "Fixed Income",
  },
];

export const HEADER_PRODUCT_MENU = [
  {
    label: "Fractional Ownership",
    src: "/products/fractional-ownership",
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/building2.svg",
  },
  // {
  //   label: "Corporate Bonds",
  //   src: "/products/corporate-bonds",
  //   icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/crown-2.svg",
  // },
  // {
  //   label: "Securitized Debt Instruments",
  //   src: "/products/sdi",
  //   icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/archive-book.svg",
  // },
  // {
  //   label: "Portfolio Management Services",
  //   src: "/products/cream-pms",
  //   icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/triangle.svg",
  // },
];
export const HEADER_KNOWLEDGE_CENTER_MENU = [
  // {
  //   label: "Blogs",
  //   src: "",
  //   icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/building2.svg",
  // },
  // {
  //     label: 'Webinars & Podcasts',
  //     src: '/',
  //     icon: 'https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/crown-2.svg'
  // },
  {
    label: "News (PR)",
    src: "/news-room",
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/archive-book.svg",
  },
  {
    label: "FAQs",
    src: "/faq",
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/triangle.svg",
  },
];

export const RelationshipArr = [
  "Father",
  "Mother",
  "Son",
  "Daughter",
  "Sibling",
  "Spouse",
  "Grandparents",
  "Other",
];
export const PRODUCT_TYPE = [
  {
    label: "All",
    value: "all",
    param: "",
  },
  {
    label: "Fractional Ownership",
    value: "fractional-ownership",
    param: "Fractional",
  },
  {
    label: "Corporate Bonds",
    value: "corporate-bonds",
    param: "Bonds",
  },
  {
    label: "Securitised Debt Instruments",
    value: "sdi",
    param: "SDI",
  },
];

export const MOBILE_SIDEBAR_LINKS = [
  // {
  //   icon: "opportunities.svg",
  //   label: "Opportunities",
  //   src: "/investment-opportunities",
  // },
  {
    icon: "portfolio.svg",
    label: "Portfolio Overview",
    src: "/dashboard/portfolio-overview",
  },
];
export const PEOPLE_AND_CLTURE_TEXT1 =
  "We strongly believe that our people and culture are the building blocks of the company. At WiseX, we promote diversity within a collaborative framework to optimise solutions from a multifaceted approach.";
export const PEOPLE_AND_CLTURE_TEXT2 =
  "The team comprises individuals with expertise in varying fields such as architecture, real estate, engineering, computer science and finance. Diversity is our strength and is unified in our vision and passion of reshaping an antiquated industry.";
export const GROWTH_STORY_HEADING = "The Indian Real Estate ";
export const GROWTH_STORY = "Growth Story";

export const FRACTIONAL_OWNERSHIP = "Own a Piece of Rent Yielding";
export const FRACTIONAL_OWNERSHIP2 = "Commercial Real Estate!";
export const FRACTIONAL_OWNERSHIP_TEXT =
  "Earn upto 10% monthly rentals and benefit from Capital Appreciation.";
export const WISEX_ADVANTAGE =
  "At WiseX, we're not just another investment platform; we're your key to a world of real estate opportunities";

export const SDI_HERO_HEADING1 = "Invest in Curated Fixed Income";
export const SDI_HERO_HEADING2 = "SDI Opportunities";
export const SDI_HERO_SUB_HEADING =
  "Earn upto 14% IRR with Interest and Principal repayment at Regular Intervals";

export const INVESTMENT_COMPARE_HEADIN1 = "What is Fractional Ownership in";
export const INVESTMENT_COMPARE_HEADING2 = "Commercial Real Estate?";
export const INVESTMENT_COMPARE_PARA =
  "Fractional Ownership is a Shared Ownership Structure where multiple Investors pool their funds to acquire Grade A Commercial Real Estate. Each Investor then holds a proportionate stake in the property, granting them a corresponding share of the Rental Income";
export const HOW_IT_WORK_HEADING1 = "How it works";
export const HOW_IT_WORK_HEADING2 = "with WiseX";

export const ADD_USER_HEADING = "Invest for your family together on WiseX";
export const ADD_USER_PARA =
  "Create a family group with up to 6 people and access investment opportunities, designed for helping you grow your families wealth";
export const SDI_HOW_WORK_DATA = [
  {
    label: "Choose your SDI",
    text: "Begin your Investment by choosing the SDI you would like to Invest in.",
  },
  {
    label: "Start Investing with the minimum amount",
    text: "You can Start your Investment journey into SDIs with 15 Lacs",
  },
  {
    label: "Enjoy Monthly Returns",
    text: "Your Investments earn you a Monthly Rental Returns.",
  },
  {
    label: "Enjoy Monthly/Quarterly Returns",
    text: "Your Investments will earn you Monthly/Quarterly Returns.",
  },
  {
    label: "Track your performance",
    text: "Monitor your Investment's performance at a glance with the Investment Dashboard.",
  },
  {
    label: "Sell your SDIs",
    text: "Have the flexibility to sell your SDIs when you choose.",
  },
];
export const HOW_IT_WORK_DATA = [
  {
    label: "Select your Property",
    text: "Begin your Fractional Ownership journey by choosing the Property you'd like to Invest in.",
  },
  {
    label: "Start Investing with the minimum amount",
    text: "You can start your Investment journey into Fractional Ownership with just ₹25 Lacs.",
  },
  {
    label: "Enjoy Monthly Returns",
    text: "Your Investments earn you a Monthly Rental Returns.",
  },
  {
    label: "Track your performance",
    text: "Monitor your Investment's Performance at a glance with the Investment Dashboard.",
  },
  {
    label: "Liquidate your Investment",
    text: "Have a Flexibility to sell your Investment when you choose.",
  },
];

export const SDI_HOW_IT_WORK_DATA = [
  {
    label: "Choose your SDI",
    text: "Begin your Investment in Securitized Debt by choosing the SDI you'd like to invest in.",
  },
  {
    label: "Start Investing with the minimum amount",
    text: "You can start your investment journey into SDIs with just 1 lakh rupees.",
  },
  {
    label: "Enjoy Monthly/Quarterly Returns",
    text: "Your investments earn you monthly/quarterly returns.",
  },
  {
    label: "Track your performance",
    text: "Monitor your investment's performance at a glance with the Investment Dashboard.",
  },
  {
    label: "Sell your SDIs",
    text: "Have the flexibility to sell your SDIs when you choose.",
  },
];

export const WHY_SDI_KEYPOINTS = [
  {
    label: "High Returns",
    value: "Earn 11-14% IRR with a fixed Investor Payout Schedule.",
  },
  {
    label: "Secured Instrument",
    value:
      "Backed by 1.2X or more Collaterals [Locked-in Receivables or Real estate] accompanied by Guarantees [Operator, Corporate or Personal]",
  },
  {
    label: "Listed & Rated Instrument",
    value:
      "Listed on BSE/NSE Stock Exchange with Securities transferred to  Investor's Demat Account.Tier I Rating agencies provide Credit assessments, enhancing the Issuance's Credibility.",
  },
  {
    label: "Stable and Non- Market Linked Returns",
    value:
      "The Instrument offers Stable, Non-Market Linked Returns, ensuring Predictability and Consistency for Investors",
  },
];
export const CORPORATE_KEYPOINTS = [
  {
    label: "High Returns",
    value: "Earn 11-14% IRR with a fixed Investor Payout Schedule.",
  },
  {
    label: "Secured Instrument",
    value:
      "Backed by 1.2X or more Collaterals [Locked-in Receivables or Real estate] accompanied by Guarantees [Operator, Corporate or Personal]",
  },
  {
    label: "Listed & Rated Instrument",
    value:
      "Listed on BSE/NSE Stock Exchange with Securities transferred to  Investor's Demat Account. Tier I Rating agencies provide Credit assessments, enhancing the Issuance's Credibility.",
  },
  {
    label: "Stable and Non-Market Linked Returns",
    value:
      "The Instrument offers Stable, Non-Market Linked Returns, ensuring Predictability and Consistency for Investors",
  },
];

export const QUICK_LINK = "Quick Links";

export const HERO_DATA = [
  {
    title: "₹ 400+ Cr",
    text: "Investments Facilitated",
    value: 100000,
  },
  {
    title: "60,000+",
    text: "Total number of Users",
    value: 2000,
  },
  {
    title: "100%",
    text: "On Time Investor Payout",
    value: 11.2,
  },
  {
    title: "250+",
    text: "Awards & Publications",
    value: 50,
  },
];
export const ABOUT_HERO_DATA = [
  {
    title: "400+",
    text: "Crore",
  },
  {
    title: "60,000+",
    text: "Users",
  },
  {
    title: "100%",
    text: "On-time Payouts",
  },
  {
    title: "250+",
    text: "Awards & Publications",
  },
];

export const WISEX_FACTOR = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/wallet-money.svg",
    label: "Monthly Income",
    text: "Monthly Returns from the point of Investment ",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/bank.svg",
    label: "Institutional Opportunities",
    text: "Less than 1% of the Opportunities pass the stringent WiseX Selection Criteria",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/profile-tick-plane.svg",
    label: "Expertise",
    text: "Over 25 years of experience in Real Estate sector",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/login.svg",
    label: "Access",
    text: "Easy Access to Institutional Grade Investment Opportunities",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/diagram.svg",
    label: "Data Driven",
    text: "Data Driven Investment approach to identify the Optimal assets based on 150+ Data points",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/eye.svg",
    label: "Transparency",
    text: "Transparent investment process, access to detailed Reporting and complete Disclosures",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/coffee.svg",
    label: "Convenience",
    text: "End to end Investment Lifecycle support with a Dedicated Investment Manager",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/money-recive-green.svg",
    label: "Liquidity",
    text: "Tech enabled platform provides improved liquidity with multiple exit options",
  },
];

export const OUR_VALUES_DATA = [
  {
    icon: "integrity.svg",
    label: "We Win with Integrity",
    text: "We stand firm on the principle that the success is most meaningful when achieved with unwavering integrity.",
  },
  {
    icon: "innovation.svg",
    label: "We Never stop Innovating",
    text: "At WiseX, Innovation is not just a process but a relentless pursuit. We never stop innovating, pioneering a future where creativity knows no bounds.",
  },
  {
    icon: "passion.svg",
    label: "We Convert Passion into Action",
    text: "At the heart of our culture is our belief, that passion when transformed into action becomes the driving force propelling us toward excellence.",
  },
  {
    icon: "customer.svg",
    label: "We Put our Customer’s First",
    text: "At the heart of our ethos lies the unwavering commitment where every decision & action displays our dedication to exceeding customer expectations, ensuring their satisfaction defines our success.",
  },
  {
    icon: "do-more.svg",
    label: "We Do More with Less",
    text: "Embracing efficiency as a cornerstone, we thrive on 'doing more with less,' catalyzing innovation to achieve excellence with resourcefulness.",
  },
];

export const WISEX_PHILOSOPHY = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/watch-status.svg",
    heading: "Risk Averse",
    text: "Each asset is selected based on stringent criteria involving predictive analytics across 150+ data points, 25 years of industry expertise, and our proprietary technology process.",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/linetriangle.svg",
    heading: "Transparent",
    text: "Transparent investment process, access to detailed reporting and complete costing disclosures. All relevant documents are shared with each investor via the WiseX dashboard. Transparency, honesty, and integrity are core values at WiseX.",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/tick-circle.svg",
    heading: "Accessible",
    text: "Each Investor will be provided a customized WiseX dashboard to access all details and track performance of their assets. Each investor will be assigned a personal relationship manager who can be contacted via an integrated feature in the WiseX dashboard. The WiseX team can be reached at any of our offices in Bangalore, Mumbai, and Delhi.",
  },
];

export const DASHBOARD_SIDEBAR_ITEMS = [
  {
    label: "Portfolio Overview",
    icon: "portfolio-overview-outline",
    path: "/dashboard/portfolio-overview",
  },
  {
    label: "My Investments",
    icon: "chart-outline",
    path: "/dashboard/my-investments",
  },
  {
    label: "Transactions",
    icon: "empty-wallet-outline-",
    path: "/dashboard/transactions",
  },
  {
    label: "Settings",
    icon: "setting-outline",
    subMenu: [
      {
        label: "Profile",
        children: "",
        path: "/dashboard/settings/profile",
      },
      {
        label: "Manage Accounts",
        children: "",
        path: "/dashboard/settings/manage-accounts",
      },
      {
        label: "Account Preferences",
        children: "",
        path: "/dashboard/settings/account-preferences",
      },
    ],
  },
];

export const INVEST_STRATEGY = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/heart-add.svg",
    label: "Unprecedented Access",
    text: "The first-of-its-kind real estate centric PMS in India. A joint collaboration between Integrow AMC and WiseX.",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/security.png",
    label: "Downside Protection & Upside Participation",
    text: "The CREAM Strategy offers access to adiversified Real Estate portfolio that aims to capture long-term growth through.",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/document-text.svg",
    label: "Actively Managed Multi-Asset Allocation",
    text: "Strategic diversification across listed equities, listed debt and listed REITs. The portfolio is actively managed to identify optimal.",
  },
];

export const INVEST_SECTION_DATA = [
  {
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/user1.webp",
    title: "Explore Institutional Grade Investment Opportunities",
    points: [
      "Thorough scrutiny involving over 150 Data Points; Less than 1% opportunities go live.",
      "Implementing measures to minimise Potential Risks in each Investment",
    ],
  },
  {
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/user2.webp",
    title: "Effortlessly Complete KYC and Invest",
    points: [
      "Streamlined Digital KYC process for ease",
      "Convenient e-signing for quicker Document processing.",
      "Seamless Payment process for a smooth experience.",
    ],
  },
  {
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/user1.webp",
    title: "Enjoy Returns on Your Schedule",
    points: [
      "Receive Monthly/Quarterly Payouts in your Bank Account",
      "Dedicated Investment Manager during the entire Investment Cycle",
    ],
  },
];
export const INVEST_PORTFOLIO_DATA = [
  {
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/portfolio-1.png",
    title: "Real-Time Big-Data Analytics",
    points: [
      "Analyze historical stock performance, market trends, and macroeconomic indicators on a scale that was previously impossible.",
    ],
  },
  {
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/portfolio-2.png",
    title: "Robot Process Automation",
    points: [
      "Proprietary RPA technology to automate time-consuming and repetitive tasks involved in research and analysis.",
    ],
  },
  {
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/portfolio-3.png",
    title: "Artificial Intelligence & Machine Learning",
    points: [
      "Identify hidden patterns and trends that human analysts might miss, offering a deeper insight into market dynamics.",
    ],
  },
];

export const FOOTER_LOGO_TEXT =
  "We strongly believe that our people and culture are the building blocks of the company. ";
export const FOOTER_COPYRIGHTS = "© Copyright 2024, All Rights Reserved";
export const DISCLAIMER = "Disclaimer:";
export const DISCLAIMER_CONTENT =
  "All the information on this website www.bondscapital.in is published in good faith and for general information purpose only. WiseX does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website www.bondscapital.in, is strictly at your own risk. WiseX will not be liable for any losses and/or damages in connection with the website.";

export const FOOTER_QUICK_LINKS = [
  {
    label: "Fractional Ownership",
    url: "/products/fractional-ownership",
  },
  {
    label: "Securitised Debt Instruments",
    url: "/products/sdi",
  },
  {
    label: "Corporate Bonds",
    url: "/products/corporate-bonds",
  },
  {
    label: "Fractional Ownership Returns Calculator",
    url: "/fractional-ownership-returns-calculator",
  },  
  {
    label: "About Us",
    url: "/about-us",
  },
  {
    label: "FAQs",
    url: "/faq",
  },
  {
    label: "Newsroom",
    url: "/news-room",
  },
  {
    label: "Contact Us",
    url: "/contact-us",
  },
];

export const FOOTER_REST_LINKS = [
  {
    boldLabel: "Aurum Proptech",
    label: "A Venture by",
    url: "https://www.aurumproptech.in/",
    target: "_blank",
  },
  {
    label: "Privacy Policy ",
    url: "/privacy-policy",
  },
  {
    label: " Terms & Conditions",
    url: "/terms-and-conditions",
  },
];

export const MOBILE_DASHBOARD_FOOTER = [
  {
    icon: "dashboard-products.svg",
    label: "Products",
    url: "/dashboard/portfolio-overview",
  },
  {
    icon: "dashbaord-building.svg",
    label: "Opportunities",
    url: "/investment-opportunities",
  },
  {
    icon: "dashboard-book.svg",
    label: "Portfolio Overview",
    url: "/dashboard/portfolio-overview",
  },
];

export const CONNECT_WITH_US = "Connect with us on";

export const SOCIAL_MEDIA_ICONS = [
  // {
  //     icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/twitter.svg",
  //     src: "https://twitter.com/AurumWiseX",
  // },
  // {
  //     icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/facebook.svg",
  //     src: "https://www.facebook.com/AurumWiseX/",
  // },
  // {
  //   icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/insta.webp",
  //   src: "https://www.instagram.com/aurumwisex/",
  // },
  // {
  //   icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/linkedin.webp",
  //   src: "https://www.linkedin.com/company/aurumwisex/",
  // },
];

export const GROWTH_STORY_KEYHIGHLIGHTS_CARD = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/money-send-green.svg",
    heading: "US$ 1 Trillion",
    text: "Market size by 2030 & 13% Contribution to India’s GDP",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/diagram.svg",
    heading: "18.4% CAGR",
    text: "Over the last 5 years outperforming other asset classes",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/mouse.svg",
    heading: "19.5% CAGR",
    text: "Expected to grow steadily with strong underlying macro fundamentals",
  },
];

export const REGULATORY_COMPLIANCE = [
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/sebi.svg",
    text: "SEBI Registered Debt Broker",
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/bse.svg",
    text: "OBPP License Holder",
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/iccl.svg",
    text: "Transaction Security through Indian Clearing Corporation Ltd",
  },
];
export const INVETMENT_HEADING1 = " Cream of the Crop:";
export const INVETMENT_HEADING2 = " The CREAM Investment Strategy";
export const FRACTIONAL_TABLEDATA_COL1 = [
  "",
  "Distribution",
  "Rental Yield",
  "Capital Appreciation",
  "Total Returns (IRR)",
  "Stability",
  "Risks",
];
export const FRACTIONAL_TABLEDATA_COL2 = [
  "",
  "Monthly",
  "High (7-10%)",
  "4% - 9%",
  "11% - 19%",
  "Long Term Lease with MNC Tenants",
  "Low",
];
export const FRACTIONAL_TABLEDATA_COL3 = [
  "",
  "Monthly",
  "Low (2-3%)",
  "2% - 5%",
  "4% - 8%",
  "Unstable Tenancy Due to short lease tenures with individuals as Tenants.",
  "Medium",
];
export const MICRO_TABLE_HEADING = "Commercial";
export const MICRO_TABLE_HEADING2 = " is Better";
export const MICRO_TABLE_SUBHEADING =
  "Commercial real estate typically offers 3X - 4X better returns than residential investment options. The performance of fractional ownership investments is not closely related to the stock market.";
export const MICRO_TABLEDATA_COL1 = [
  "",
  "Property Type",
  "Rental Yield",
  "Capital Appreciation",
  "Total Returns (IRR)",
  "Stability",
  "Risks",
];
export const MICRO_TABLEDATA_COL2 = [
  "",
  "Offices, Warehouses,  Data centres, Commercial Land etc.",
  "High (7-9%)",
  "High (4-9%)",
  "High (11-18%)",
  "Long Term Lease with MNC Tenants",
  "Low",
];
export const MICRO_TABLEDATA_COL3 = [
  "",
  "Houses, Flats, Plots, etc.",
  "Low (2-3%)",
  "Low (2-5%)",
  "Low (4-8%)",
  "Short Term Lease with Individuals as Tenants",
  "Medium",
];

export const STRATEGY_ALLOCATION = "Strategy & ";

export const INVESTMENT_PARTNERS = [
  {
    name: "Ramashrya Yadav",
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/Pms+Partners/Ramashrya-Yadav.png",
    position: "Founder and CEO, Integrow AMC",
    about:
      "Ram is the Founder and CEO of Integrow Asset Management and holds experience in all facets of real estate like construction, investment management and banking. He believes in transforming the real estate asset management market by providing knowledge, good governance, tech-enabled solutions, and value growth for all stakeholders.",
    keyPoints: [
      {
        label: "Previously Managed",
        value: "₹ 5000 cr",
      },
      {
        label: "Years of Expertise",
        value: "20+",
      },
    ],
    pastExp: [
      {
        logo: "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/Pms+Partners/client-experience-1.svg",
        exp: "CEO-Real Estate Practice (2014-2020)",
      },
      {
        logo: "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/Pms+Partners/client-experience-2.svg",
        exp: "Joint CEO (2013 - 2014)",
      },
      {
        logo: "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/Pms+Partners/client-experience-3.svg",
        exp: "EA- Director (2000 - 2002)",
      },
    ],
  },
  {
    heading: "Integrow Asset Management",
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/client-2.png",
    about:
      "Ram is the Founder and CEO of Integrow Asset Management and holds experience in all facets of real estate like construction, investment management and banking. He believes in transforming the real estate asset management market by providing knowledge, good governance, tech-enabled solutions, and value growth for all stakeholders.",
    keyPoints: [
      {
        label: "Previously Managed",
        value: "₹ 5000 cr",
      },
      {
        label: "Years of Expertise",
        value: "20+",
      },
      {
        label: "Active Funds",
        value: "3+",
      },
    ],
  },
];

export const PORTFOLIO_HEADING_TEXT =
  "Underlying portfolio will comprise a diversified selection of securities. 3,500+ securities are evaluated and only the top 30 are selected in the portfolio. The curation process entails real time evaluation across 100+ data points and stringent selection criteria.";

export const CONTACT_US_TABS = [
  {
    city: "Mumbai",
    iFrame:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15079.367675228881!2d73.0085516!3d19.1145897!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c0c429a42e41%3A0x52aac88741198e09!2sWiseX!5e0!3m2!1sen!2sin!4v1718349940551!5m2!1sen!2sin",
    address:
      "Aurum Q1, Aurum Q Parć, Thane - Belapur Rd, Reliance Corporate Park, MIDC Industrial Area, Ghansoli, Navi Mumbai, Maharashtra 400710",
  },
];

export const ABOUT_HERO_SUB_HEADING =
  "WiseX is India’s First & Largest Neo-realty investment platform by Aurum PropTech.";
export const ABOUT_HERO_HEADING = "Navigating the";
export const ABOUT_HERO_HEADING_SPAN = "Wealth of Opportunities";

export const OUR_PARENT_COMPANY = [
  "Aurum PropTech is a new-age PropTech company, listed on the National Stock Exchange (NSE) as 'AURUM' and Bombay Stock Exchange as 'BOM:539289' with its headquarters at Navi Mumbai.",
  "As a torchbearer of the Indian PropTech landscape, dedicated to revolutionising the RealEstate industry, Aurum PropTech has a meticulously crafted business portfolio of 13+ PropTech products & solutions with operations in 50+ cities, 600 RealEstate Developer relations, 7500+ Channel Partners, 8000+ active home buyers, 4300+ active RaaS customers and 520+ active SaaS customers.",
  "The company builds and operates PropTech products, services, and platforms catering to the intricacies of the B2B, B2C, B2B2C, and D2C RealEstate Value Chains. Aurum PropTech's business portfolio of products and solutions encompasses nearly 75% of India’s PropTech market potential and is built on cutting-edge technologies such as Artificial Intelligence, Machine Learning, Blockchain, Augmented Reality, and Virtual Reality. At the core of Aurum’s strategy lies a triad: Technology, Services, and Capital.",
];

export const OUR_STORY = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/send-2.svg",
    text: "Launched in",
    label: "2020",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/profile-add.svg",
    text: "Team",
    label: "70+",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/send.svg",
    text: "Average IRR*",
    label: "13.4%",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/building.svg",
    text: "Offices in",
    label: "3 Cities",
  },
];

export const TEAM_MEMBER_CARD_DATA = [
  {
    image: "nitin.webp",
    name: "Nitin Virmani",
    position: "Head of Marketing & Product",
    exPosition: "Ex Shemaroo, Lead School",
    education: "MBA - IIM Rohtak",
    bio: "Nitin is a seasoned marketing professional having expertise in scaling Digital businesses.",
    linkedinUrl: "https://www.linkedin.com/in/nitin-virmani",
  },
  {
    image: "kushal.webp",
    name: "Kushal Singh",
    position: "VP - Finance",
    exPosition: "Ex Grip Invest",
    education: "Charted Accountant",
    bio: "Kushal is an adept CA, with extensive experience in the accounting, Tax, Auditing, and Financial Analysis. He aces in alternative investment structures such as Securitized Debt Instrument (SDI).",
    linkedinUrl: "https://www.linkedin.com/in/kushal-singh-11ba7390/",
  },
  {
    image: "rihen.webp",
    name: "Rihen Shah",
    position: "Strategy Manager",
    exPosition: "Ex Bain & Company",
    education: "MBA - NMIMS",
    bio: "Rihen is an MBA and a strategy professional having previously worked at Bain & Co. & Cipla.",
    linkedinUrl: "https://www.linkedin.com/in/rihen-shah-1a6a66148/",
  },
];

// ---------------Image Imports----------------------------------------------------------------------------------------------

export const START_KYC_SUCESS =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/start-kyc.svg";
export const WISEX_LOGO_LIGHT =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/wisex-lite-logo.svg";
export const HERO_IMG =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/heroImagecompress.webp";
export const HERO_IMG_MOBILE =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/heroImgMobile.webp";
export const FACTOR_BG =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/pattern.svg";
export const TESTIMONIALS_BG =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/testimonialsBg.svg";
export const X_IMG =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/X.svg";
export const STRATEGY_GRAPH =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/strategy-allocation-graph.svg";
export const CONTACT_US_IMG =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/contact-us-new.webp";


export const AWARDS_IMAGES_data = [
  {
    image: "award0.webp",
    text: "Proptech Emerging Brand of the Year 2024",
  },
  {
    image: "award1.webp",
    text: "PropTech StartUp of the year 2023",
  },
  {
    image: "award2.webp",
    text: "Illustrations Startup of the Year 2023",
  },
  {
    image: "award3.webp",
    text: "PropTech Start- Up of the Year 2023 Entrepreneur of the Year 2023",
  },
  {
    image: "award4.webp",
    text: "Finance & Proptech Startup of the Year 2023",
  },
  {
    image: "award5.webp",
    text: "40 Under 40 2023",
  },
  {
    image: "award7.webp",
    text: "PropTech company 2022",
  },
];
export const AWARDS_DATA = [
  {
    image: "",
  },
];

export const GROWTH_STORY_IMAGE =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/growt-graph.svg";

export const PMS_PARTNERS = [
  {
    logo: "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img3.png",
    exp: "Fund Account",
  },
  {
    logo: "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img2.png",
    exp: "Brokerage",
  },
  {
    logo: "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/legal.png",
    exp: "Legal",
  },
];
export const SDI_CREDIT_RISK = [
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/moneys-1.svg",
    label: "Internal Credit Evaluation",
    keyPoints: ["Business Evaluation", "Intent Evaluation", "Ability to Repay"],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/profile-tick-plane.svg",
    label: "Legal Due Diligence, Valuation, and Credit Rating:",
    keyPoints: [
      "Conducting rigorous multi-level checks: in-house team, Tier 1 firms",
      "Ensuring legality and soundness of Assets and Company",
      "Assessment of collaterals & company valuation",
      "Credit Rating Assessments",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/chart-success.svg",
    label: "Deal Structuring:",
    keyPoints: [
      "Escrow mechanism - Establish first charge on receivables",
      "Collateral cover over receivables",
      "Corporate & personal guarantee, if required",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/micro-market.svg",
    label: "Deal Listing:",
    keyPoints: [
      "Highly selective approach",
      "Less than 1% of scrutinised properties make it to the platform",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/building.svg",
    label: "Post Approval Monitoring",
    keyPoints: [
      "Assess Financial Health",
      "Monitor Convenants ",
      "Pre-emptive Corrective Actions when necessary",
    ],
  },
];

export const CORPORATE_BONDS_STRINGENT_EVALUATION = [
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/moneys.svg",
    label: "Internal Credit Evaluation:",
    keyPoints: [
      "Financial Analysis [for last 3 FY] including GST & Banking",
      "Intent Evaluation - CIBIL, Borrowing Profile, Legal/Corporate Governance",
      "Evaluation of Receivables Cash flows and Lock-ins",
      "Bad Debt History Analysis",
      "Credit Call with Management",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/moneys.svg",
    label: "Legal Due Diligence, Valuation, and Credit Rating:",
    keyPoints: [
      "Conducting rigorous multi-level checks: in-house team, Tier 1 firms",
      "Ensuring legality and soundness of Assets and Company",
      "Assessment of collaterals & company valuation",
      "Credit Rating Assessments",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/moneys.svg",
    label: "Deal Structuring:",
    keyPoints: [
      "Escrow mechanism - Establish first charge on receivables",
      "Collateral cover over receivables",
      "Corporate & personal guarantee, if required",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/moneys.svg",
    label: "Deal Listing:",
    keyPoints: [
      "Highly selective approach",
      "Less than 1% of scrutinized properties make it to the platform",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/moneys.svg",
    label: "Post-Approval Monitoring:",
    keyPoints: [
      "Assess Financial Health",
      "Monitor Convenants",
      "Pre-emptive Corrective Actions when necessary",
    ],
  },
];

export const CREDIT_RISK_CARD_DATA = [
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/asset-evaluation.svg",
    label: "Asset Evaluation",
    keyPoints: [
      "Build Quality",
      "Tenant Profile",
      "Lease Agreements ",
      "Tenure, Lock ins, Rental & Escalation",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/micro-market.svg",
    label: "Micro-market Assessment",
    keyPoints: [
      "Demand Forecast and its Growth Potential",
      "Infrastructure Developments Planned",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/profile-tick-plane.svg",
    label: "Legal Due Diligence and Valuation",
    keyPoints: [
      "Conducting Rigorous multi-level checks: In-house team, Tier 1 & Local firm",
      "Legality & Soundness of Titles and Agreements",
      "Finding Right Valuation",
    ],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/chart-success.svg",
    label: "Financial & Tax Analysis",
    keyPoints: ["Expert Financial modelling", "Tax efficiency"],
  },
  {
    img: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/building.svg",
    label: "Property Listing",
    keyPoints: [
      "Exceedingly Selective approach",
      "Less than 1% of scrutinised properties make it to the platform",
    ],
  },
];

export const SDI_HEADING = "What are SDIs?";
export const SDI_HEADING_TEXT =
  "Securitised Debt Instrument [SDI] is a Rated & Listed Fixed Return Financial Instrument introduced by SEBI in 2008. Trust purchases Future Receivables and issues securities to the Investors. The Future Receivables are then distributed among the Investors in accordance with the Payout Schedule";

export const SDI_TABLE_DATA = {
  leftTable: [
    "",
    "IRR %",
    "Repayment",
    "Volatility",
    "Predictable Returns",
    "Security Cover",
  ],
  rightTable: [
    {
      className: "CommercialRealEstate SDICommercialRealEstate",
      tableData: [
        {
          label: "SDIs",
        },
        {
          label: "Upto 17%",
          icon: "arrow_outward.svg",
        },
        {
          label: "Monthly/Quarterly",
          icon: "arrow_outward.svg",
        },
        {
          label: "Low",
          icon: "arrow_outward.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
      ],
    },
    {
      className: "ResidentialRealEstate FixedDeposit",
      tableData: [
        {
          label: "Fixed Deposit",
        },
        {
          label: "Upto 7%",
          icon: "arrow_outward_bottom.svg",
        },
        {
          label: "End of Tenure",
          icon: "arrow_outward_bottom.svg",
        },
        {
          label: "Low",
          icon: "arrow_outward_bottom.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
      ],
    },
  ],
};

export const ACCOUNT_TYPE_FOR_SIGNUP = [
  {
    label: "Resident Individual",
    value: "Individual",
  },
  {
    label: "NRI",
    value: "NRI",
  },
  {
    label: "Private Limited Company",
    value: "Private",
  },
  {
    label: "HUF",
    value: "Huf",
  },
  {
    label: "LLP",
    value: "LLP",
  },
  {
    label: "Partnership",
    value: "Partnership",
  },
];

export const WHY_CORPORATE_BONDS_HEADING = "What are Corporate Bonds?";
export const WHY_CORPORATE_BONDS_HEADING_TEXT =
  "Corporate Bonds are like loans that you give to big companies. When you invest in corporate bonds, you're actually lending your money to a company.In return, they promise to pay you back your money, with a bit extra called 'interest.' These can be seen in your Demat account which you will provide during your investment.";

export const WHY_CORPORATE_BONDS_TABLE_DATA = {
  leftTable: [
    "",
    "IRR %",
    "Repayment",
    "Volatility",
    "Predictable Returns",
    "Security Cover",
    "Fixed Income",
  ],
  rightTable: [
    {
      className: "CommercialRealEstate SDICommercialRealEstate CorporateBonds",
      tableData: [
        {
          label: "Corporate Bonds",
        },
        {
          label: "12-15%",
          icon: "arrow_outward.svg",
        },
        {
          label: "Monthly/Quarterly",
          icon: "arrow_outward.svg",
        },
        {
          label: "Low",
          icon: "arrow_outward.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
      ],
    },
    {
      className: "ResidentialRealEstate SDICenterTable",
      tableData: [
        {
          label: "Fixed Deposit",
        },
        {
          label: "Upto 7%",
          icon: "arrow_outward_bottom.svg",
        },
        {
          label: "End of Life",
          icon: "arrow_outward_bottom.svg",
        },
        {
          label: "Low",
          icon: "arrow_outward_bottom.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
        {
          label: "Yes",
          icon: "arrow_outward.svg",
        },
      ],
    },
  ],
};
// -------------------Icons Imports------------------------------------------------------------------------------------------
export const LOCATION =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/location.svg";
export const LIVE =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/live.svg";
export const GRAPH =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/graph.svg";
export const LOGO_ICON =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/Logo_BondsCapitalNew.svg";
export const LOGO_ICON_AURUM =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/Logo_BondsCapital.webp";
export const HAMBURGER_ICON =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/hamburger.svg";

export const ARROW_LEFT =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/arrow-left.svg";
export const ARROW_RIGHT =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/arrow-right.svg";
export const VIDEO_RECORD_ICON =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/video-recorder.svg";
export const PHONE_ICON =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/call-outgoing.svg";
export const MAIL_ICON =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/sms.svg";
export const ARROW_DOWN_BLACK =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/arrow-down-black.svg";
export const ARROW_UP_PRIMARY =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/arrow-up-green.svg";
export const ARROW_RIGHT_WHITE =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/arrow-narrow-right.svg";
export const CIRCLE_GRAY_CHECK =
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/check-circle-gray.svg";

export const OCCUPATION_SELECT_OPTIONS = [
  {
    label: "Business",
    value: "Business",
  },
  {
    label: "Private Sector",
    value: "Private Sector",
  },
  {
    label: "Public Sector",
    value: "Public Sector",
  },
  {
    label: "Govt. Sector",
    value: "Govt. Sector",
  },
  {
    label: "Professional",
    value: "Professional",
  },
  {
    label: "Self Employed",
    value: "Self Employed",
  },
  {
    label: "Housewife",
    value: "Housewife",
  },
];
export const NOMINEE_RELATIONSHIP = [
  {
    label: "Father",
    value: "father",
  },
  {
    label: "Mother",
    value: "mother",
  },
  {
    label: "Son",
    value: "son",
  },
  {
    label: "Daughter",
    value: "daughter",
  },
  {
    label: "Sibling",
    value: "sibling",
  },
  {
    label: "Spouse",
    value: "spouse",
  },
  {
    label: "Grandparents",
    value: "Grandparents",
  },
];
export const MARITAL_STATUS_SELECT_OPTIONS = [
  {
    label: "Married",
    value: "married",
  },
  {
    label: "Unmarried",
    value: "unmarried",
  },
  {
    label: "Others",
    value: "other",
  },
];
export const INCOME_SELECT_OPTIONS = [
  {
    label: ">1 crore",
    value: "1crore",
  },
  {
    label: "25 lacs - 1 crore",
    value: "25lacs-1crore",
  },
  {
    label: "10-25 lacs",
    value: "10-25lacs",
  },
  {
    label: "5-10 lacs",
    value: "5-10lacs",
  },
  {
    label: "1-5 lacs",
    value: "1-5lacs",
  },
  {
    label: "<1 lac",
    value: "1lac",
  },
];

export const BROKER_NAME = [
  {
    label: "Zerodha",
    value: "zerodha",
  },
  {
    label: "Groww",
    value: "groww",
  },
  {
    label: "Upstox",
    value: "upstox",
  },
  {
    label: "Fyers",
    value: "fyers",
  },
  {
    label: "ICICI Securities",
    value: "icici-securities",
  },
  {
    label: "Kotak Securities",
    value: "kotak-securities",
  },
];

// ------------------------------Slider Settings---------------------------------------------------------------------------------

export const WE_WORK_WITH_SLIDER_SETTINGS = {
  slidesToShow: 8,
  slidesToScroll: 1,
  speed: 1600,
  autoplaySpeed: 1,
  pauseOnHover: false,
  infinite: true,
  arrows: false,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 8,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

export const TESTIMONIALS_SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: true,
  autoplay: true,
  speed: 8000,
  autoplaySpeed: 10,
  arrows: false,
  cssEase: "linear",
  centerMode: true,
};

export const TESTIMONIALS_MID_SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: true,
  autoplay: true,
  speed: 8000,
  autoplaySpeed: 1,
  arrows: false,
  cssEase: "linear",
  rtl: true,
  centerMode: true,
};

export const MEDIA_COVARAGE_SLIDER_SETTINGS = {
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 5600,
  autoplaySpeed: 1,
  infinite: true,
  arrows: false,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const PEOPLE_AND_CULTURE_SLIDER_SETTINGS = {
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 9600,
  autoplaySpeed: 100,
  pauseOnHover: true,
  infinite: true,
  arrows: false,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const MICRO_LANDING_TESTIMONIALS_SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  // autoplay: true,
  // speed: 6000,
  // autoplaySpeed: 100,
  centerPadding: "20px",
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const CREDIT_SLIDER_SETTINGS = {
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplaySpeed: 3000, // Set a reasonable autoplay speed in milliseconds
  infinite: true, // Set to true if you want continuous scrolling, false otherwise
  arrows: false,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const INVESTMENT_PARTNER_SLIDER_SETTINGS = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  arrows: false,
  cssEase: "linear",
};

export const RECENT_OPPORTUNITY = [
  {
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/ListingCard.png",
    propertyName: "Magarpatta Cybercity - 1",
    propertyLocation: "Hadapsar, Pune",
    status: "live",
    achievement: {
      total: "3.5 Cr",
      outOf: "3.6 Cr",
      growth: 92,
    },
    propertyDetails: [
      {
        label: "Asset Value",
        value: "₹ 24.9 Cr",
      },
      {
        label: "IRR",
        value: "18.5%",
      },
      {
        label: "Average Rental Yield:",
        value: "8.8%",
      },
    ],
  },
  {
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/ListingCard.png",
    propertyName: "Magarpatta Cybercity - 1",
    propertyLocation: "Hadapsar, Pune",
    status: "closed",
    achievement: {
      total: "3.5 Cr",
      outOf: "3.6 Cr",
      growth: 92,
    },
    propertyDetails: [
      {
        label: "Asset Value",
        value: "₹ 24.9 Cr",
      },
      {
        label: "IRR",
        value: "18.5%",
      },
      {
        label: "Average Rental Yield:",
        value: "8.8%",
      },
    ],
  },
  {
    image:
      "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/ListingCard.png",
    propertyName: "Magarpatta Cybercity - 1",
    propertyLocation: "Hadapsar, Pune",
    status: "waitlist",
    achievement: {
      total: "3.5 Cr",
      outOf: "3.6 Cr",
      growth: 92,
    },
    propertyDetails: [
      {
        label: "Asset Value",
        value: "₹ 24.9 Cr",
      },
      {
        label: "IRR",
        value: "18.5%",
      },
      {
        label: "Average Rental Yield:",
        value: "8.8%",
      },
    ],
  },
];

export const COMMERCIAL_REAL_ESTATE_DATA = [
  {
    label: "High Rental Yield",
    text: "7-10% rental yield with stable and predictable cash flow",
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/trend-up.svg",
  },
  {
    label: "Capital Appreciation",
    text: "Year on year capital appreciation (5%-9% IRR)",
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/clipboard.svg",
  },
  {
    label: "Capital Preservation",
    text: "Real estate is a hard asset that preserves capital",
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/circle-chart.svg",
  },
  {
    label: "Stable and Non-Correlated",
    text: "The performance of commercial real estate is not closely correlated with stocks and bonds",
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/candle-2.svg",
  },
];

export const COMMERCIAL_REAL_ESTATE_GRAPH_DATA = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/bank-gray.svg",
    heading: "Fixed Deposits",
    keypoints: ["Stable", "Lower Returns"],
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/building.svg",
    heading: "A-Grade Real Estate",
    keypoints: ["Stable", "Higher Returns"],
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/diagram-gray.svg",
    heading: "Stocks & Mutual Funds",
    keypoints: ["Volatile", "Higher Returns"],
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/kanban.svg",
    heading: "Gold",
    keypoints: ["Volatile", "Lower Returns"],
  },
];

// -------------------Animations----------------------------------------------------------------------------------------------

export const SCALE_UP = {
  whileHover: { scale: 1.05, y: -10 },
  transition: { type: "just", duration: 0.1, delay: 0 },
};
export const TOP_TO_BOTTOM = {
  transition: { type: "just", duration: 0.6, delay: 0.25 },
  variants: {
    hidden: { opacity: 0, y: -55 },
    visible: { opacity: 1, y: 0 },
  },
  initial: "hidden",
  animate: "visible",
};
export const BOTTOM_TO_TOP = {
  transition: { type: "just", duration: 0.6, delay: 0.25 },
  variants: {
    hidden: { opacity: 0, y: 55 },
    visible: { opacity: 1, y: 0 },
  },
  initial: "hidden",
  animate: "visible",
};

export const RIGHT_TO_LEFT = {
  transition: { type: "just", duration: 0.6, delay: 0.25 },
  variants: {
    hidden: { opacity: 1, x: 100 },
    visible: { opacity: 1, x: 0 },
  },
  initial: "hidden",
  animate: "visible",
};

export const LEFT_TO_RIGHT = {
  transition: { type: "just", duration: 0.6, delay: 0.25 },
  variants: {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, backgroundColor: "red" },
  },
  initial: "hidden",
  duration: 0.2,
  // animate: 'visible'
};

export const DASHBOARD_RECENT_TANSACTIONS_COL = [
  {
    field: "title",
    headerName: "Opportunity Name",
    minWidth: 250,
    flex: 1,
    renderCell: (param) => (
      <div className="OpportunityNameRenderCell">
        <img
          loading="lazy"
          src="https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/building2.svg"
          alt="building2"
        />
        <p>{param.value}</p>
      </div>
    ),
  },
  {
    field: "date",
    headerName: "Date",
    minWidth: 120,
    flex: 1,
    renderCell: (param) => (
      <div className="AmountRenderCell">
        <p>{convertDate(param?.value)}</p>
      </div>
    ),
  },
  {
    field: "transaction_id",
    headerName: "Transaction Id",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "amount",
    headerName: "Amount",
    minWidth: 160,
    flex: 1,
    renderCell: (param) => (
      <div className="AmountRenderCell">
        <p className={param.row.type === "cr" ? "Credited" : "Debited"}>{`${param.row.type === "cr" ? "+" : "-"
          } ${param.value} INR`}</p>
      </div>
    ),
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 200,
    maxwidth: 400,
    flex: 1,
  },
  {
    field: "receipt",
    headerName: "",
    minWidth: 100,
    flex: 1,
    renderCell: (param) => <PrintReceiptButton receiptUrl={param.value} />,
  },
];

export const TRANSACTION = [
  {
    field: "date",
    headerName: "Date",
    width: 200,
    renderCell: (param) => (
      <div className="AmountRenderCell">
        <p>{convertDate(param?.value)}</p>
      </div>
    ),
  },
  {
    field: "transation_amount",
    headerName: "Gross Interest",
    width: 260,
    renderCell: (param) => {
      return (
        <>
          {param?.row?.type == "Debit" ? (
            <p style={{ color: "#C83535" }}>- {param?.value} INR</p>
          ) : (
            <p style={{ color: "#00c08d" }}>+ {param.value} INR</p>
          )}
        </>
      );
    },
  },
  {
    field: "transation_id",
    headerName: "Transaction ID",
    width: 220,
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 320,
    flex: 1,
  },
  {
    field: "receipt",
    headerName: "",
    width: 100,
    renderCell: (param) => <PrintReceiptButton receiptUrl={param.value} />,
  },
];

export const TRANSACTION_PAGE_COLS = [
  {
    field: "date",
    headerName: "Date",
    width: 100,
    renderCell: (param) => (
      <div className="AmountRenderCell">
        <p>
          {new Date(param?.value * 1000).toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            year: "2-digit",
          })}
        </p>
      </div>
    ),
  },
  {
    field: "transation_amount",
    headerName: "Gross Interest",
    width: 160,
    renderCell: (param) => (
      <>
        {param?.row?.type == "Debit" ? (
          <p style={{ color: "#C83535" }}>- {param?.value} INR</p>
        ) : (
          <p style={{ color: "#00c08d" }}>+ {param.value} INR</p>
        )}
      </>
    ),
  },
  {
    field: "opportunity_name",
    headerName: "Opportunity Name",
    width: 200,
  },
  {
    field: "deal_category",
    headerName: "Product Type",
    width: 120,
  },
  {
    field: "transation_id",
    headerName: "Transaction ID",
    width: 110,
  },
  {
    field: "description",
    headerName: "Description",
    width: 260,
  },
  {
    field: "receipt",
    headerName: "",
    width: 100,
    renderCell: (param) => <PrintReceiptButton receiptUrl={param.value} />,
  },
];

export const REPAYMENT_SCHEDULE = [
  {
    field: "repayment_date",
    headerName: "Date",
    width: 200,
    renderCell: (param) => (
      <div className="AmountRenderCell">
        <p>
          {new Date(param?.value * 1000).toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            year: "2-digit",
          })}
        </p>
      </div>
    ),
  },
  {
    field: "principal_outstanding",
    headerName: "Outstanding",
    width: 220,
  },
  {
    field: "interest_repayment",
    headerName: "Interest",
    width: 220,
  },
  {
    field: "principal_repayment",
    headerName: "Principal",
    width: 210,
  },
  {
    field: "total-return",
    headerName: "Total Return",
    width: 210,
    renderCell: (params) => {
      return (
        <>{params.row.principal_repayment + params.row.interest_repayment}</>
      );
    },
  },
];

export const RETURN_CALCULATOR_COLS = [
  {
    field: "id",
    headerName: "#",
    width: 60,
  },
  {
    field: "repayment_date",
    headerName: "Date",
    width: 130,
    // renderCell: (param) => (
    //     <div className='AmountRenderCell'>
    //         <p>{new Date(param?.value * 1000).toLocaleDateString('en-US', {
    //             day: 'numeric',
    //             month: 'short',
    //             year: '2-digit',
    //         })}</p>
    //     </div>
    // )
    renderCell: (param) => (
      <>
        {new Date(param?.value * 1000).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "2-digit",
        })}
      </>
    ),
  },
  {
    field: "principal_outstanding",
    headerName: "Outstanding",
    width: 130,
    renderCell: (param) => <>₹ {param.value}</>,
  },
  {
    field: "interest_repayment",
    headerName: "Interest",
    width: 150,
    // renderCell: (param) => (
    //     <div className='AmountRenderCell'>
    //         <p className={param.row.type === 'cr' ? 'Credited' : 'Debited'}>{`₹ ${param.row.type === 'cr' ? '+' : '-'} ${param.value}`}</p>
    //     </div>
    // )
    renderCell: (param) => <>₹ {param.value}</>,
  },
  {
    field: "principal_repayment",
    headerName: "Principal",
    width: 130,
    renderCell: (param) => <>₹ {param.value}</>,
  },
  {
    field: "return",
    headerName: "Total Return",
    width: 120,
  },
];
export let PORTFOLIO_CARD_DATA = [
  {
    title: "Total Amount Invested",
    icon: "total-investments.svg",
    value: "₹0",
  },
  {
    title: "Total Returns Received",
    icon: "returns-recievd.svg",
    value: "₹0",
  },
  {
    title: "Active Investments ",
    icon: "flash-investments.svg",
    value: "0",
  },
  {
    title: "Matured Investments",
    icon: "returns-recievd.svg",
    value: "0",
  },
];
export let GraphData = [
  { value: 0, color: "#d9f0ffff", label: "Fractional" },
  { value: 0, color: "#ccebdfff", label: "SDI" },
  { value: 0, color: "#ffffd0", label: "Bonds" },
];

export const addDataToPORTFOLIO_CARD_DATA = (dashData) => {
  if (Object.values(dashData?.category_wise_total_investment).length > 0) {
    PORTFOLIO_CARD_DATA[0].value = `₹ ${formatNumber(
      Object.values(dashData?.category_wise_total_investment).reduce(
        (accumulator, currentValue) => accumulator + currentValue
      )
    )}`;
  }
  if (Object.values(dashData?.category_wise_total_returns).length > 0) {
    PORTFOLIO_CARD_DATA[1].value = `₹ ${formatNumber(
      Object.values(dashData?.category_wise_total_returns).reduce(
        (accumulator, currentValue) => accumulator + currentValue
      )
    )}`;
  }
  PORTFOLIO_CARD_DATA[2].value = dashData?.property_status?.active;
  PORTFOLIO_CARD_DATA[3].value = dashData?.property_status?.closed;

  const categories =
    Object.keys(dashData?.category_wise_total_investment) || [];
  GraphData = GraphData.filter((item) => categories.includes(item.label));
  categories.forEach((category) => {
    const categoryValue = dashData?.category_wise_total_investment?.[category];

    const index = GraphData.findIndex(
      (item) =>
        item.label.trim().toLowerCase() === category.trim().toLowerCase()
    );

    if (categoryValue !== undefined && index !== -1) {
      GraphData[index].value = categoryValue;
    }
  });
};

export const UNITS_SUGGESTION = [
  {
    label: "+1 Units",
    value: 1,
  },
  {
    label: "+5 Units",
    value: 5,
  },
  {
    label: "+10 Units",
    value: 10,
  },
];
export const UNITS_SUGGESTION_FRACTIONAL = [
  {
    label: "+1 Lac",
    value: 100000,
  },
  {
    label: "+5 Lac",
    value: 500000,
  },
  {
    label: "+10 Lac",
    value: 1000000,
  },
];

export const PROPERTY_DETAILS_CAL_NOTE = [
  "Available for users having DEMAT account with either NSDL or CDSL",
  "NRIs are not permitted to invest",
  "Monthly payments will be made after deducting TDS",
];
export const PROPERTY_DETAILS_CAL_NOTE_FRACTIONAL = [
  "Monthly payments will be made after deducting TDS",
];

// -------------------Temp Data----------------------------------------------------------------------------------------------

export const LISTING_TABS = [
  {
    catName: "Fractional Ownership",
    bullets: [
      "Investments starting at ₹ 25 Lacs",
      "Benefit from monthly rental income & capital appreciation over time",
      "Earn 14-20% IRR ",
    ],
    listingHeading: "Build your Real Estate Portfolio!",
    cardData: [
      {
        image:
          "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/ListingCard.png",
        propertyName: "Magarpatta Cybercity - 1",
        propertyLocation: "Hadapsar, Pune",
        status: "live",
        achievement: {
          total: "3.5 Cr",
          outOf: "3.6 Cr",
          growth: 92,
        },
        propertyDetails: [
          {
            label: "Asset Value",
            value: "₹ 24.9 Cr",
          },
          {
            label: "IRR",
            value: "18.5%",
          },
          {
            label: "Average Rental Yield:",
            value: "8.8%",
          },
        ],
      },
      {
        image:
          "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/ListingCard.png",
        propertyName: "Magarpatta Cybercity - 2",
        status: "closed",
        propertyLocation: "Hadapsar, Pune",
        achievement: {
          total: "3.5 Cr",
          outOf: "3.6 Cr",
          growth: 92,
        },
        propertyDetails: [
          {
            label: "Asset Value",
            value: "₹ 24.9 Cr",
          },
          {
            label: "IRR",
            value: "18.5%",
          },
          {
            label: "Average Rental Yield:",
            value: "8.8%",
          },
        ],
      },
    ],
  },
  // {
  //   catName: "Corporate Bonds",
  //   bullets: [
  //     "Investments starting at ₹ 15 Lacs",
  //     "High security cover",
  //     "Earn fixed rate of return 14-20% IRR",
  //   ],
  //   listingHeading: "Invest by Lending to Corporates",
  //   cardData: [
  //     {
  //       image:
  //         "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/ListingCard.png",
  //       propertyName: "Magarpatta Cybercity - 3",
  //       status: "live",
  //       propertyLocation: "Hadapsar, Pune",
  //       achievement: {
  //         total: "3.5 Cr",
  //         outOf: "3.6 Cr",
  //         growth: 92,
  //       },
  //       propertyDetails: [
  //         {
  //           label: "Asset Value",
  //           value: "₹ 24.9 Cr",
  //         },
  //         {
  //           label: "IRR",
  //           value: "18.5%",
  //         },
  //         {
  //           label: "Average Rental Yield:",
  //           value: "8.8%",
  //         },
  //       ],
  //     },
  //     {
  //       image:
  //         "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/ListingCard.png",
  //       propertyName: "Magarpatta Cybercity - 4",
  //       status: "closed",
  //       propertyLocation: "Hadapsar, Pune",
  //       achievement: {
  //         total: "3.5 Cr",
  //         outOf: "3.6 Cr",
  //         growth: 92,
  //       },
  //       propertyDetails: [
  //         {
  //           label: "Asset Value",
  //           value: "₹ 24.9 Cr",
  //         },
  //         {
  //           label: "IRR",
  //           value: "18.5%",
  //         },
  //         {
  //           label: "Average Rental Yield:",
  //           value: "8.8%",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   catName: "SDIs",
  //   bullets: [
  //     "Investments starting at ₹ 15 Lacs",
  //     "High security cover",
  //     "Earn fixed rate of return 12-16% IRR",
  //   ],
  //   listingHeading: "Invest in Rated & Listed SDIs",
  //   cardData: [
  //     {
  //       image:
  //         "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/ListingCard.png",
  //       propertyName: "Magarpatta Cybercity - 3",
  //       status: "waitlist",
  //       propertyLocation: "Hadapsar, Pune",
  //       achievement: {
  //         total: "3.5 Cr",
  //         outOf: "3.6 Cr",
  //         growth: 92,
  //       },
  //       propertyDetails: [
  //         {
  //           label: "Asset Value",
  //           value: "₹ 24.9 Cr",
  //         },
  //         {
  //           label: "IRR",
  //           value: "18.5%",
  //         },
  //         {
  //           label: "Average Rental Yield:",
  //           value: "8.8%",
  //         },
  //       ],
  //     },
  //     {
  //       image:
  //         "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/ListingCard.png",
  //       propertyName: "Magarpatta Cybercity - 4",
  //       propertyLocation: "Hadapsar, Pune",
  //       status: "waitlist",
  //       achievement: {
  //         total: "3.5 Cr",
  //         outOf: "3.6 Cr",
  //         growth: 92,
  //       },
  //       propertyDetails: [
  //         {
  //           label: "Asset Value",
  //           value: "₹ 24.9 Cr",
  //         },
  //         {
  //           label: "IRR",
  //           value: "18.5%",
  //         },
  //         {
  //           label: "Average Rental Yield:",
  //           value: "8.8%",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const WE_WORK_WITH_SLIDER_IMG = [
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img1.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img2.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img3.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img4.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img5.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img6.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img7.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img8.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img1.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img2.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img3.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img4.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img5.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img6.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img7.png",
  "https://ddk0b51k9qpea.cloudfront.net/public-resources/images/img8.png",
];
export const PEOPLE_AND_CULTURE = [
  {
    image: "tech-team.png",
    text: "Tech Team - 2024",
  },
  {
    image: "people-and-culture1.webp",
    text: "Christmas -2023",
  },
  {
    image: "people-and-culture2.webp",
    text: "Diwali -2023",
  },
  {
    image: "people-and-culture3.webp",
    text: "Elite Partner Connect - 2023",
  },
  {
    image: "people-and-culture4.webp",
    text: "Leadership Sync During Starship Mission 2024",
  },
  {
    image: "people-and-culture5.webp",
    text: "New Office Pooja Ceremony",
  },
  {
    image: "people-and-culture6.webp",
    text: "Office Fun Activities",
  },
  {
    image: "people-and-culture7.webp",
    text: "Office Fun Activities",
  },
  {
    image: "people-and-culture8.webp",
    text: "Offsite -2021",
  },
];

export const INVESTER_TYPE = [
  {
    icon: "resident-citizen",
    label: "Resident Indian Citizen (Individual)",
    link: "/kyc/Individual",
  },
  {
    icon: "kyc-nri",
    label: "NRI",
    link: "/kyc/NRI",
  },
  {
    icon: "company-investor-type",
    label: "Private Limited Company",
    link: "/kyc/Private",
  },
  {
    icon: "huf",
    label: "HUF",
    link: "/kyc/Huf",
  },
  {
    icon: "non-profit",
    label: "LLP",
    link: "/kyc/LLP",
  },
  {
    icon: "partnership-llp",
    label: "Partnership",
    link: "/kyc/Partnership",
  },
];

export const MICRO_LANDING_PRODECT_FACTORS = [
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/eye.svg",
    label: "Total Asset Value",
    text: "₹47.13Cr",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/arrange-square.svg",
    label: "Total Area",
    text: "58,661",
    sub: "Sq.ft",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/money-recive-green.svg",
    label: "Minimum Investments",
    text: "₹25 Lacs",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/buildings-2.svg",
    label: "Property Type",
    text: "Commercial Office",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/bank.svg",
    label: "Avg. rental Yield",
    text: "9.5%",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/lock.svg",
    label: "Tenant Lock-in",
    text: "5 Years",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/diagram.svg",
    label: "Rental Escalation",
    text: "10%",
    sub: "every 2 years",
  },
  {
    icon: "https://ddk0b51k9qpea.cloudfront.net/public-resources/icons/wallet-money.svg",
    label: "Pre-tax IRR",
    text: "15.1%",
  },
];

export const MICRO_TESTIMONIALS_DATA = [
  {
    id: 5,
    name: "Abishek Bogaram",
    company_name: "The Raymond Shop",
    designation: "Managing Partner",
    company_location: "Bengaluru",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/f0d92340-bb6c-11ee-9375-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/d86685d0-bb6e-11ee-af47-e2f477421b7b.webp",
    description:
      "It's been more than 2 years with WiseX, and the experience has exceeded my expectations. Their commitment to transparency has made the journey seamless, completely paperless & rewarding.",
    linkedin_url: "https://www.linkedin.com/in/abishek-bogaram-6848624?",
  },
  {
    id: 9,
    name: "Guru Paladi",
    company_name: "Amazon Web Services",
    designation: "Software Engineering Manager",
    company_location: "New York",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/335f0a7c-bb6d-11ee-8d12-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/10f47330-bb6f-11ee-89b3-e2f477421b7b.webp",
    description:
      "Investing with WiseX was a game-changer for me. The tech-enabled liquidity options and detailed reporting they provide make it a trustworthy platform.",
    linkedin_url: "https://www.linkedin.com/in/gurupaladi",
  },
  {
    id: 7,
    name: "Mayank Sharma",
    company_name: "Maruti Suzuki India Limited",
    designation: "Manager",
    company_location: "Delhi",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/54838426-bb6d-11ee-a241-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/280d6b6c-bb6f-11ee-b3fe-e2f477421b7b.webp",
    description:
      "As a regular investor at WiseX, I can say that the team is intelligent & hardworking. I am constantly updated about my portfolio due to the fantastic technology they are using.",
    linkedin_url: "https://www.linkedin.com/in/mayank-sharma-6039177/",
  },
  {
    id: 1,
    name: "Manish Kaushal",
    company_name: "McAfee",
    designation: "Director - Data Engineering",
    company_location: "Bengaluru",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/444135ea-bb6d-11ee-8a91-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/1ccc09ac-bb6f-11ee-8e89-e2f477421b7b.webp",
    description:
      "5-star rating to WiseX for the transparency they provide. My Investment Manager is always available to clarify my doubts and I am now enjoying consistent & timely monthly returns.",
    linkedin_url: "http://linkedin.com/",
  },
  {
    id: 12,
    name: "Vishvesh Chandra Sharma",
    company_name: "ICICI Bank",
    designation: "Banking Consultant",
    company_location: "Delhi",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/3b435584-bb5b-11ee-b4ec-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/3b4c9cca-bb5b-11ee-b881-e2f477421b7b.webp",
    description:
      "Post-retirement, I was in search of an opportunity that could offer me solid returns on my corpus. They offered me a structured debt opportunity that was aligned with my financial goals.",
    linkedin_url: "",
  },
  {
    id: 2,
    name: "Puneet Bery",
    company_name: "KSP INC",
    designation: "Founder",
    company_location: "Delhi",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/867b808c-bb6d-11ee-9c9d-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/4af00144-bb6f-11ee-8551-e2f477421b7b.webp",
    description:
      "I was impressed right from my first conversation with WiseX’s Investment Manager, offering clarity. I am confident in reinvesting my hard-earned money in newer opportunities brought by them.",
    linkedin_url: "https://www.linkedin.com/in/puneet-bery-52aab4216/",
  },
  {
    id: 3,
    name: "Samrath Bedi",
    company_name: "Forest Essentials",
    designation: "Executive Director",
    company_location: "Delhi",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/d46586ba-bb56-11ee-8c84-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/d46f5faa-bb56-11ee-82d1-e2f477421b7b.webp",
    description:
      "WiseX uses fantastic technology and offers investment opportunities that are backed by research. The transparency they provide helps me trust the platform with my hard-earned money.",
    linkedin_url: "https://www.linkedin.com/in/samrath-bedi-5954a8202/",
  },
  {
    id: 4,
    name: "Dheeraj Bansal",
    company_name: "Athenahealth",
    designation: "Director - Engineering",
    company_location: "Bengaluru",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/255ca1b4-bb6d-11ee-9895-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/0394cc26-bb6f-11ee-943e-e2f477421b7b.webp",
    description:
      "After years of exploring investments, I found the best option at WiseX. They provide fixed income, capital preservation & high returns beating inflation. The team is intelligent & dedicated.",
    linkedin_url: "https://www.linkedin.com/in/dabansal/",
  },

  {
    id: 6,
    name: "Pankaj Dixit",
    company_name: "Executive",
    designation: "BetterUp",
    company_location: "Bengaluru",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/785b52f2-bb6d-11ee-94a5-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/3f0b07ca-bb6f-11ee-8731-e2f477421b7b.webp",
    description:
      "I am very impressed. Their debt investment options align perfectly with my investment goals. I truly recommend WiseX to fellow investors seeking a trustworthy platform.",
    linkedin_url: "https://www.linkedin.com/in/papcc",
  },

  {
    id: 8,
    name: "Dhanachandran Ambalavanan",
    company_name: "Volvo Group",
    designation: "Principle Engineer",
    company_location: "Bengaluru",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/16b60038-bb6d-11ee-a5ce-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/f54bfd24-bb6e-11ee-ab2c-e2f477421b7b.webp",
    description:
      "What sets WiseX apart is not just the returns, but the seamlessness of the platform that makes managing investments a pleasure, it's more than a financial partnership.",
    linkedin_url: "https://www.linkedin.com/in/dhanachandran-ambalavanan",
  },

  {
    id: 10,
    name: "Neelam Tanwani",
    company_name: "Mosaic",
    designation: "Product Manager Leader",
    company_location: "San Francisco",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/6aa124fc-bb6d-11ee-8f5d-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/32fc8738-bb6f-11ee-933e-e2f477421b7b.webp",
    description:
      "WiseX is my go-to for real estate investments. The rare blend of institutional opportunities and user-friendly access makes it the perfect choice.",
    linkedin_url: "https://www.linkedin.com/in/neelam-tanwani",
  },
  {
    id: 11,
    name: "Anurag Ahuja",
    company_name: "AS Logistics",
    designation: "Founder",
    company_location: "Gurugram",
    company_logo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/0174aa3a-bb6d-11ee-bfcc-e2f477421b7b.png",
    profile_photo:
      "https://d1hmyq8nm1i2xx.cloudfront.net/testimonial/ea2d6c34-bb6e-11ee-947d-e2f477421b7b.webp",
    description:
      "Kudos to WiseX! Their curated alternative investment opportunities and transparent process gave me confidence in every step. It is a trustworthy platform.",
    linkedin_url: "http://linkedin.com/",
  },
];




export const WISEX_PAGES_META_DATA = {
  "/": {
    title: "WiseX - India’s Top Fractional Ownership Real Estate Investment Firm",
    description: "Invest in A-grade platinum rated commercial real estate with WiseX - India's first and largest fractional ownership commercial estate investment platform.",
    keywords: "WiseX, Myre Capital, Fractional Ownership, Commercial Real Estate, Alternative Investments, REIT, SM REIT, Preleased property"
  },
  "/signin": {
    title: "Sign In | WiseX - A Fractional Ownership Platform",
    description: " Sign in to access your WiseX account and seamlessly manag all of your fractional ownership investments and stay updated about new investment opportunities.",
    keywords: "WiseX signin, WiseX login, WiseX dahsboard"
  },
  "/signup": {
    title: "Sign Up | WiseX - A Fractional Ownership Platform",
    description: "Join WiseX today to start investing in commercial real estate. Sign up to create your account and avail benefits of our alternative investment opportunities.",
    keywords: "WiseX, signup, register, invest in real estate"
  },
  "/about-us": {
    title: "About Us | WiseX - A Fractional Ownership Investment Platform",
    description: "WiseX is India's first and largest fractional ownership platform, democratising commercial real estate by helping investors own fractions of high-value properties.",
    keywords: "WiseX, Myre Capital, Fractional Ownership, Commercial Real Estate, Alternative Investments, REIT, SM REIT, Preleased property"
  },
  "/contact-us": {
    title: "Contact Us | WiseX - A Fractional Ownership Investment Platform",
    description: "Reach out to WiseX for trustworthy and secure fractional property investment solutions in India. Discover our fractional ownership real estate opportunities today.",
    keywords: "WiseX, WiseX contact, support, WiseX Office, WiseX Delhi, WiseX Bengaluru, WiseX Mumbai"
  },
  "/products/fractional-ownership": {
    title: "Fractional Ownership | WiseX - Best Alternative Investment Platform",
    description: "WiseX's Fractional Ownership investment opportuinites allow you to invest in A+ grade commercial real estate properties with the highest rental yeilds & appreciation.",
    keywords: "fractional ownership, investments, WiseX, Myre Capital, Fractional Ownership, Commercial Real Estate, Alternative Investments, REIT, SM REIT, Preleased property"
  },
  "/products/corporate-bonds": {
    title: "Corporate Bonds | WiseX - Best Alternative Investment Platform",
    description: "Explore WiseX's corporate bonds. Invest in fixed income securities to diversify your portfolio, and secure stable returns in the form of interest on your investment.",
    keywords: "corporate bonds, investments"
  },
  "/products/sdi": {
    title: "Securitised Debt Instruments | WiseX - Best Alternative Investment Platform",
    description: "Diversify your portfolio by investing in Securitised Debt Instruments with WiseX. Start investing with as little as 15 lakhs and recieve monthly interest payments.",
    keywords: "SDI, investments"
  },
  "/products/cream-pms": {
    title: "Portfolio Management | WiseX - Best Alternative Investment Platform",
    description: "Discover WiseX's Cream PMS - the real-estate centric investment tool designed to enhance portfolio performance with precision and reliability. Click to learn more!",
    keywords: "portfolio management, investments"
  },
  "/faq": {
    title: "FAQ's | WiseX - A Fractional Ownership Investment Platform",
    description: "Looking for answers to queries related to Fractional Ownership, investment processes, taxation and much more? Find answer to your question in our FAQ section.",
    keywords: "WiseX, faq, help, how to invest in fractional ownership, who can invest"
  },
  "/kyc": {
    title: "KYC | WiseX - A Fractional Ownership Investment Platform",
    description: "Complete your KYC within 2 minutes. Whether you are a resident Indian, NRI, or a business Partnership, verifing your personal details and banking details is super simple and easy.",
    keywords: "kyc, verification"
  },
  "/dashboard/portfolio-overview": {
    title: "Portfolio Overview | WiseX - A Fractional Ownership Platform",
    description: "Get a snapshot of all your active investments with WiseX, ranging from corporate bonds to high-value commercial real estate fractional ownership investments.",
    keywords: "WiseX portfolio, investments, WiseX Dashboard"
  },
  "/dashboard/settings/": {
    "title": "Profile Settings | WiseX - A Fractional Ownership Platform",
    description: "Customise your WiseX profile settings. Update personal info, complete your KYC, and manage your fractional ownership commercial real estate investments efficiently.",
    keywords: "profile, settings"
  },
  "/dashboard/settings/profile": {
    "title": "Profile | WiseX - A Fractional Ownership Platform",
    description: "Customise your WiseX profile settings. Update personal info, complete your KYC, and manage your fractional ownership commercial real estate investments efficiently.",
    keywords: "profile, settings"
  },
  "/dashboard/settings/manage-accounts": {
    title: "Manage Accounts | WiseX - A Fractional Ownership Platform",
    description: "Effortlessly manage your all linked accounts on WiseX. Securely add, edit, or remove family accounts to streamline your fractional ownership investment experience.",
    keywords: "manage accounts, settings"
  },
  "/dashboard/settings/account-preferences": {
    title: "Account Preference | WiseX - A Fractional Ownership Platform",
    description: "Effortlessly manage your all accounts Preference on WiseX. Securely add, edit, or remove family accounts to streamline your fractional ownership investment experience.",
    keywords: "manage accounts, settings"
  },
  "/dashboard/transactions": {
    title: "Transactions | WiseX - A Fractional Ownership Platform",
    description: "Track & download your transactions seamlessly on WiseX intuitive transaction dashboard. Stay updated with real-time data on your alternative investment assets.",
    keywords: "transactions, history"
  },
  "/dashboard/my-investments": {
    title: "My Investments | WiseX - A Fractional Ownership Platform",
    description: "Explore performance reports for all your active investments with WiseX. Download transaction details and all opportunity documents in a single place.",
    keywords: "my investments, portfolio"
  },
  "/add-account": {
    title: "Add Account | WiseX - A Fractional Ownership Platform",
    description: "Add an account to your WiseX profile to consolidate and manage all your investments seamlessly in one place, ensuring a hassle-free experience.",
    keywords: "add account, new account"
  },
  "/add-account/existing": {
    title: "Add Existing Account | WiseX - A Fractional Ownership Platform",
    description: "Add an existing account to your WiseX profile to consolidate and manage all your investments seamlessly in one place, ensuring a hassle-free experience.",
    keywords: "add account, new account"
  },
  "/add-account/new": {
    title: "Add New Account | WiseX - A Fractional Ownership Platform",
    description: "Create a new account with WiseX and start your investing journey! Expand your portfolio effortlessly with our seamless investment management tools and services.",
    keywords: "add account, new account"
  },
  "/investment-opportunities": {
    title: "Opportunities | WiseX - A Fractional Ownership Platform",
    description: "Learn about the most lucrative investment opportunities - fractional ownership, corporate bonds, securitised debt instruments. Invest consistently with WiseX!",
    keywords: "investment opportunities, investments, how to invest in fractional ownership, WiseX, Myre Capital, Fractional Ownership, Commercial Real Estate, Alternative Investments, REIT, SM REIT, Preleased property"
  },
  "/terms-and-conditions": {
    title: "Terms and Conditions | WiseX - A Fractional Ownership Platform",
    description: "Learn how WiseX handles your personal data with our detailed privacy policy. Protecting your information is our priority. Read all the information carefully.",
    keywords: "terms and conditions"
  },
  "/news-room": {
    title: "Newsroom | WiseX - A Fractional Ownership Investment Platform",
    description: "Discover WiseX's recent publications in prominent finance newspapers and online news portals like Forbes, Mint, CNBC, Hindustan Times and The Economic Times",
    keywords: "news, updates"
  },
  "/privacy-policy": {
    title: "Privacy Policy | WiseX - A Fractional Ownership Platform",
    description: "Review WiseX platform's terms and conditions before investing. Understand your rights and responsibilities as a user of our fractional ownership platform.",
    keywords: "privacy policy"
  },
  "/fractional-ownership-investments-sky-one-corporate-park-pune": {
    title: "WiseX - India's leading Alternative Investments Platform",
    description: "Invest in Sky One Corporate Park Pune",
    keywords: "fractional ownership, Sky One Corporate Park, WiseX, Myre Capital, Fractional Ownership, Commercial Real Estate, Alternative Investments, REIT, SM REIT, Preleased property"
  }
}




// Micro Landing Data 

export const FractionalKeyHighlights2 = [
  {
    icon: 'bank.svg',
    label: 'Tenant Type: MNC',
  },
  {
    icon: 'lock.svg',
    label: 'Tenant Lock-in: 5 Years',
  },
  {
    icon: 'money-recive-green.svg',
    label: 'Rental Income: Distributed Monthly',
  },
];

export const tempRecentOpportunity = [
  {
    id: 6,
    image:
      'https://d1hmyq8nm1i2xx.cloudfront.net/properties/images/ebfef3c0-babf-11ee-9618-024234509018.webp',
    propertyName: 'Vaishnavi Tech Park II',
    propertyLocation: 'Outer Ring Road Sarjapur, Bangalore',
    status: 'closed',
    achievement: {
      total: '35.7 Cr',
      outOf: '35.7 Cr',
      growth: 100,
    },
    propertyDetails: [
      {
        label: 'Asset Value',
        value: '₹35.7 Cr',
      },
      {
        label: 'IRR',
        value: '15.9%',
      },
      {
        label: 'Average Rental Yield',
        value: '8.8%',
      },
    ],
  },
  {
    id: 1,
    image:
      'https://d1hmyq8nm1i2xx.cloudfront.net/properties/images/611c2337be237-property-image.png',
    propertyName: 'Magarpatta Cybercity - 1',
    propertyLocation: 'Hadapsar, Pune',
    status: 'exited',
    achievement: {
      total: '24.9 Cr',
      outOf: '24.9 Cr',
      growth: 100,
    },
    propertyDetails: [
      {
        label: 'Asset Value',
        value: '24.9 Cr',
      },
      {
        label: 'IRR',
        value: '18.5%',
      },
      {
        label: 'Average Rental Yield',
        value: '8.8%',
      },
    ],
  },
  {
    id: 5,
    image:
      'https://d1hmyq8nm1i2xx.cloudfront.net/properties/images/4e41ff30-baae-11ee-accc-024234509018.png',
    propertyName: 'Vaishnavi Tech Park I',
    propertyLocation: 'Outer Ring Road Sarjapur, Bangalore',
    status: 'closed',
    achievement: {
      total: '35.4 Cr',
      outOf: '35.4 Cr',
      growth: 100,
    },
    propertyDetails: [
      {
        label: 'Asset Value',
        value: '₹35.4 Cr',
      },
      {
        label: 'IRR',
        value: '16.2%',
      },
      {
        label: 'Average Rental Yield',
        value: '9%',
      },
    ],
  },
];

export const productDetails = [
  {
    label: "New Building :",
    value:
      "New Building : The Tower has been newly constructed with state of the art construction technology to deliver superior quality. ",
  },
  {
    label: "Strong Tenant Demand :",
    value:
      "The building is already majorly occupied by blue chip tenants such as Vertiv Energy, Simpliworks, Kantar, Sincro & Piaggio Vehicles.",
  },
  {
    label: "A-Grade Building :",
    value:
      "Designed for IGBC Platinum Rating [Pre-certified]. The building has state-of-the-art amenities including a gym, cafeteria, amphitheater, yoga room, creche and many more! ",
  },
  {
    label: "Locality :",
    value:
      "The asset is located in Viman Nagar near the Pune International airport. The site has close proximity to the proposed metro station.",
  },
];

export const ProductListingCardData = [
  {
    id: 20,
    image:
      "https://d1hmyq8nm1i2xx.cloudfront.net/properties/images/1a65e038-c99e-11ee-854f-0242dd07f7e3.webp",
    propertyName: "Sky One Corporate Park",
    propertyLocation: "Viman Nagar, Pune",
    status: "live",
    achievement: {
      total: "44.9 Cr",
      outOf: "47.13 Cr",
      growth: 64,
    },
    propertyDetails: [
      {
        label: "Asset Value",
        value: "₹47.13 Cr",
      },
      {
        label: "IRR",
        value: "15.1%",
      },
      {
        label: "Average Rental Yield",
        value: "9.5%",
      },
    ],
  }
];

export const galleryData = [
  {
    sorting_order: 1,
    full_url:
      "https://d1hmyq8nm1i2xx.cloudfront.net/properties/gallery/2ae0df50-c99c-11ee-9b19-0242dd07f7e3.webp",
  },
  {
    sorting_order: 2,
    full_url:
      "https://d1hmyq8nm1i2xx.cloudfront.net/properties/gallery/2af3ce80-c99c-11ee-96af-0242dd07f7e3.webp",
  },
  {
    sorting_order: 3,
    full_url:
      "https://d1hmyq8nm1i2xx.cloudfront.net/properties/gallery/2b0933d8-c99c-11ee-99ef-0242dd07f7e3.webp",
  },
  {
    sorting_order: 4,
    full_url:
      "https://d1hmyq8nm1i2xx.cloudfront.net/properties/gallery/2b1ddbb2-c99c-11ee-b062-0242dd07f7e3.webp",
  },
  {
    sorting_order: 5,
    full_url:
      "https://d1hmyq8nm1i2xx.cloudfront.net/properties/gallery/2b2c33ec-c99c-11ee-b73e-0242dd07f7e3.webp",
  },
  {
    sorting_order: 6,
    full_url:
      "https://d1hmyq8nm1i2xx.cloudfront.net/properties/gallery/2b3c7392-c99c-11ee-9cca-0242dd07f7e3.webp",
  },
  {
    sorting_order: 7,
    full_url:
      "https://d1hmyq8nm1i2xx.cloudfront.net/properties/gallery/2b46d04e-c99c-11ee-a02a-0242dd07f7e3.webp",
  },
];

export const MICROSITE_FOOTER_DATA = [
  "Disclaimer:",
  "All the information on this website www.bondscapital.in is published in good faith and for general information purpose only. WiseX does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website www.bondscapital.in, is strictly at your own risk. WiseX will not be liable for any losses and/or damages in connection with the use of this website.",
  "WiseX's research team, software, algorithms and analytical processes used have, to the best of its ability, taken into account various factors - both quantitative measures and qualitative assessments, in an unbiased manner, while designing a suitable investment model. However, such assessments carry inherent unknown risks and uncertainties linked to broad markets as well as analysts' expectations about future events.",
  "It is further clarified in explicit terms that nothing on this website shall be construed as Direct or Indirect Offer to Invest/Buy/Fund in any of Investment Schemes/Real Estate Schemes/Real Estate Properties/ Financial Schemes, launched by WiseX or it's associate entities. That nothing on this website is for solicitation or invitation to any individual/firm/ company/Association Of Persons/ entity for any Investment schemes pertaining to Real Estate or otherwise, and it is further clarified that any scheme listed on the website involves standard market risks and is neither insured by WiseX, nor we can be held liable for any monetary or other losses the investor may occur due to the same.",
  "The past performance of any opportunity listed on this website is not necessarily indicative of its future performance. WiseX is not guaranteeing or assuring any return under any of the opportunities listed on this website. You are requested to exercise prudent discretion (including by obtaining expert professional advice with regard to specific legal, tax and financial implications) prior investing in any opportunity listed on this website.",
  "From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links of useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all or any of the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone ‘bad’.",
]